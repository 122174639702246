/*
 * Legal Disclaimer
 * These web fonts are licensed exclusively for use on the following:
 * Domains belonging to Johnson&Johnson
 *
 * It is strictly forbidden to download or use these fonts on any other website domain or media.
 *
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */

/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 */
@font-face {
	font-family: 'JohnsonDisplay-Bold';
	src: url('../../assets/fonts/JohnsonDisplay-Bold.otf');
	src:
		url('../../assets/fonts/JohnsonDisplay-Bold.otf') format('otf'),
		url('../../assets/fonts/JohnsonDisplay-Bold.ttf') format('ttf'),
		url('../../assets/fonts/JohnsonDisplay-Bold.woff') format('woff'),
		url('../../assets/fonts/JohnsonDisplay-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'JohnsonDisplay-Light';
	src: url('../../assets/fonts/JohnsonDisplay-Light.otf');
	src:
		url('../../assets/fonts/JohnsonDisplay-Light.otf') format('otf'),
		url('../../assets/fonts/JohnsonDisplay-Light.ttf') format('ttf'),
		url('../../assets/fonts/JohnsonDisplay-Light.woff') format('woff'),
		url('../../assets/fonts/JohnsonDisplay-Light.woff2') format('woff2');
}

@font-face {
	font-family: 'JohnsonDisplay-Medium';
	src: url('../../assets/fonts/JohnsonDisplay-Medium.otf');
	src:
		url('../../assets/fonts/JohnsonDisplay-Medium.otf') format('otf'),
		url('../../assets/fonts/JohnsonDisplay-Medium.ttf') format('ttf'),
		url('../../assets/fonts/JohnsonDisplay-Medium.woff') format('woff'),
		url('../../assets/fonts/JohnsonDisplay-Medium.woff2') format('woff2');
}
@font-face {
	font-family: 'JohnsonDisplay-Regular';
	src: url('../../assets/fonts/JohnsonDisplay-Regular.otf');
	src:
		url('../../assets/fonts/JohnsonDisplay-Regular.otf') format('otf'),
		url('../../assets/fonts/JohnsonDisplay-Regular.ttf') format('ttf'),
		url('../../assets/fonts/JohnsonDisplay-Regular.woff') format('woff'),
		url('../../assets/fonts/JohnsonDisplay-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'JohnsonText-Bold';
	src: url('../../assets/fonts/JohnsonText-Bold.otf');
	src:
		url('../../assets/fonts/JohnsonText-Bold.otf') format('otf'),
		url('../../assets/fonts/JohnsonText-Bold.ttf') format('ttf'),
		url('../../assets/fonts/JohnsonText-Bold.woff') format('woff'),
		url('../../assets/fonts/JohnsonText-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'JohnsonText-Light';
	src: url('../../assets/fonts/JohnsonText-Light.otf');
	src:
		url('../../assets/fonts/JohnsonText-Light.otf') format('otf'),
		url('../../assets/fonts/JohnsonDisplay-Light.ttf') format('ttf'),
		url('../../assets/fonts/JohnsonDisplay-Light.woff') format('woff'),
		url('../../assets/fonts/JohnsonDisplay-Light.woff2') format('woff2');
}

@font-face {
	font-family: 'JohnsonText-Medium';
	src: url('../../assets/fonts/JohnsonText-Medium.otf');
	src:
		url('../../assets/fonts/JohnsonText-Medium.otf') format('otf'),
		url('../../assets/fonts/JohnsonText-Medium.ttf') format('ttf'),
		url('../../assets/fonts/JohnsonText-Medium.woff') format('woff'),
		url('../../assets/fonts/JohnsonText-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'JohnsonText-Regular';
	src: url('../../assets/fonts/JohnsonText-Regular.otf');
	src:
		url('../../assets/fonts/JohnsonText-Regular.otf') format('otf'),
		url('../../assets/fonts/JohnsonText-Regular.ttf') format('ttf'),
		url('../../assets/fonts/JohnsonText-Regular.woff') format('woff'),
		url('../../assets/fonts/JohnsonText-Regular.woff2') format('woff2');
}

::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.hiddenScrollbarTrack::-webkit-scrollbar-track {
	border-radius: 10px;
	box-shadow: none;
}

.hiddenButton {
	display: none;
}

html,
body {
	height: 100%;
	margin: 0px;
}
.error-msg {
	color: red;
	margin-block-start: 2px;
	margin-block-end: 2px;
	text-transform: none;
	font-family: 'JohnsonText-Regular', sans-serif;
	font-size: 12px;
}

.jodit-status-bar {
	visibility: hidden;
}

/* circular animation for loader image */
@keyframes circle {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
.ant-picker-dropdown {
	z-index: 9999;
}
label[for='aud_filter_Contingent'],
label[for='aud_filter_Employee'] {
	min-width: 160px;
}

/** 
* ant-picker css changes for tablet and  mobile devices
*/
@media (max-width: 992px) {
	.ant-picker-panel:last-child .ant-picker-date-panel {
		display: none !important;
	}

	.ant-picker-panel:first-child
		.ant-picker-date-panel
		.ant-picker-header-next-btn,
	.ant-picker-panel:first-child
		.ant-picker-date-panel
		.ant-picker-header-super-next-btn {
		visibility: initial !important;
	}

	.ant-picker-panel.ant-picker-range-wrapper {
		min-width: 288px !important;
	}

	.ant-picker-presets {
		display: none !important;
	}
}

.donutchart-tooltip {
	position: absolute;
	background-color: #000;
	color: #fff;
	padding: 5px;
	border-radius: 5px;
	pointer-events: none;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
	font-size: 12px;
	margin: -30px;
	font-family: 'JohnsonText-Regular', sans-serif;
}
