.MuiTab-root {
	text-transform: none !important;
}

.MuiInputAdornment-positionStart {
	margin: 0px 6px 6px 8px !important;
}

.MuiFilledInput-input {
	padding: 5px 4px 7px 4px !important;
}

.MuiTablePagination-menuItem {
	font-family: 'JohnsonText-Medium', sans-serif !important;
	font-size: 16px !important;
	font-weight: 700 !important;
	line-height: 20px !important;
	font-style: normal !important;
	color: #212121 !important;
}
