/* margin properties */
.mt-0 {
	margin-top: 0px !important;
}
/* padding properties */
.pr-0 {
	padding-right: 0px !important;
}
.pt-0 {
	padding-top: 0px !important;
}
/* flexbox properties */
.d-flex {
	display: flex;
}
.justify-end {
	justify-content: end !important;
}
.justify-between {
	justify-content: space-between !important;
}
.justify-flex {
	justify-content: flex-start !important;
}
.cursor-pointer {
	cursor: pointer;
}
.break-all {
	word-break: break-all !important;
}
.items-start {
	align-items: flex-start !important;
}
.flex-col {
	flex-direction: column !important;
}
.gap-0 {
	gap: 0px !important;
}
.bg-transparent {
	background-color: inherit !important;
	border: none !important;
}
